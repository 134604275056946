export default [
  'yahoo.com',
  'gmail.com',
  'hotmail.com',
  'outlook.com',
  'icloud.com',
  'bol.com.br',
  'uol.com.br',
  'globo.com',
  'globomail.com',
  'terra.com.br',
  'live.com',
  'live.com.pt',
  'windowslive.com',
  'ymail.com',
  'igmail.com',
  'msn.com',
  'email.com'
]
