<template>
  <div class="register-form">
    <div v-if="!noHeading" class="heading">
      <img
        v-if="isMobile"
        class="icon"
        :src="require('@assets/icon_calendar.svg')"
      />

      <h1 class="main">
        Comece seu teste grátis!
      </h1>

      <h2 class="sub">
        Cadastre-se e comece a aprimorar sua gestão de RH
        com a Convenia.
      </h2>
    </div>

    <opacity-transition>
      <message v-if="hasEmailError" class="mail-error">
        <p class="main">
          {{ messageError }}
        </p>
      </message>
    </opacity-transition>

    <form-builder
      class="form"
      :fields="fields"
      :id="$options.formInfo.id"
      v-model="formData"
      @submit="({ handleSubmit, ...validationObserver }, formData) =>
        handleSubmit(onSubmit(validationObserver, formData))"
    >
      <template slot="after-fields">
        <div class="disclaimer">
          Ao definir minha senha, eu aceito os
          <a :href="`${homeUrl}/termos-de-uso`" target="_blank">termos da empresa</a>
          e a
          <a :href="`${homeUrl}/politicas-de-privacidade`" target="_blank">
            política de serviço
          </a>.
        </div>

        <submit-button
          :loading="loading"
          class="submit-button"
          type="submit"
        >
          começar a testar
        </submit-button>
        <div v-if="submitError" ref="submit-error" class="submit-error">
          <span>
            Não foi possível finalizar o cadastro, tente novamente. <br>
            Se o erro persistir,
            <a :href="wppLink" target="_blank">entre em contato com nosso atendimento</a>.
          </span>
        </div>
      </template>
    </form-builder>

    <p class="recaptcha-warning">
      Este site é protegido pelo reCAPTCHA e o
      <a class="link" href="https://policies.google.com/privacy" target="_blank">GOOGLE PRIVACIDADE & TERMOS</a> e
      <a class="link" href="https://policies.google.com/terms" target="_blank">TERMOS DE SERVIÇO</a> se aplicam.
    </p>
    <slot />
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import schema from '@content/forms/new_register'
import '@modules/recaptcha/settings'
import { register as formInfo } from '@content/forms/ids/index'
import { MediaQuery } from '@convenia/mixins'
import getInvalidDomains from '@services/getInvalidDomains'
import EMAIL_INVALID_DOMAINS_FALLBACK from '@content/consts/EmailInvalidDomains'
import FormBuilder from '@components/form/Builder'
import SubmitButton from '@components/form/SubmitButton.vue'
import Message from '@components/form/Message'
import register from '@services/register'
import OpacityTransition from '@components/transitions/Opacity'

export default {
  formInfo,

  props: {
    noHeading: Boolean,
  },

  mixins: [ MediaQuery ],

  components: { FormBuilder, SubmitButton, Message, OpacityTransition },

  data: () => ({
    formData: { fullName: '', company_name: '', email: '', phone: '', password: '', password_confirmation: '' },
    loading: false,
    hasEmailError: false,
    messageError: 'E-mail inválido',
    wppLink: 'http://api.whatsapp.com/send?1=pt_BR&phone=5511978916717',
    submitError: false,
    invalidDomains: new Set(EMAIL_INVALID_DOMAINS_FALLBACK)
  }),

  computed: {
    fields () {
      return schema({ invalidDomains: this.invalidDomains })
    }
  },

  methods: {
    onSubmit (validationObserver, formData) {
      return async () => {
        this.submitError = false
        this.loading = true
        await this.callService(formData, validationObserver)
      }
    },

    async callService (formData) {
      // eslint-disable-next-line no-unused-vars
      const [ error, { emailError, emailInUse, notValidEmailHubspot, registration } = {} ] = await
      register(this.request, formData)

      if (emailInUse || notValidEmailHubspot) {
        this.loading = false
        return this.hasEmailError = true
      }

      this.$emit('prepare')

      if (error) {
        this.$emit('error')
        this.submitError = true
        this.loading = false
        this.$nextTick(() => this.$refs['submit-error'].scrollIntoView({ behavior: 'smooth' }))

        return
      }

      Object.entries(registration).forEach(([ name, value ]) => {
        Cookie.set(name, value, { domain: this.cookieDomain })
      })

      this.$emit('success')
    },

    async loadInvalidDomains () {
      const [ err, data ] = await getInvalidDomains(this.request)
      if (!err) this.invalidDomains = new Set(data)
    }
  },

  watch: {
    'formData.email' () {
      this.hasEmailError = false
    }
  },

  inject: [ 'homeUrl', 'request', 'cookieDomain' ],

  mounted () {
    Cookie.remove('active_company_id')
    Cookie.remove('token')
    Cookie.remove('cisession')
    Cookie.remove('colaborador_token')
    Cookie.remove('active_company_uuid')

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    const email = urlParams.get('email')

    if (email) {
      this.formData.email = email
    }

    this.loadInvalidDomains()
  }
}
</script>

<style lang="scss">
%center-column-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-form {
  margin-top: 66px;
  margin-bottom: 40px;

  @extend %center-column-flex;

  @include responsive (xs-mobile, mobile) {
    padding: 0 16px;
    margin-top: 40px;
  }

  & > .heading {
    @extend %center-column-flex;

    @include responsive (xs-mobile, mobile) {
      > img {
        display: none;
      }
    }
  }

  & > .heading > .icon {
    margin-bottom: 25px;
    height: 120px;
  }

  & > .heading > .main {
    color: #121E48;
    opacity: 0.9;
    font-family: $font-family-roboto-bold;
    font-size: 46px;
    letter-spacing: 0;
    line-height: 42px;
    margin-bottom: 20px;
    -webkit-font-smoothing: antialiased;

    @include responsive (xs-mobile, mobile) {
      font-size: 22px;
    }
  }

  & > .heading > .sub {
    max-width: 405px;
    opacity: 0.7;
    color: $base-text-color;
    font-family: $font-family-nunito-regular;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
    font-weight: 200;
    text-align: center;
    margin-bottom: 16px;

    @include responsive (xs-mobile, mobile) {
      max-width: 300px;
      margin-bottom: 24px;
    }
  }

  & > .form {
    $width: 508px;
    min-width: $width;
    margin-top: 16px;

    @include responsive (xs-mobile, mobile) {
      margin-top: 0px;
      width: 100%;
      min-width: unset;
      max-width: $width;
    }

    & > .fields {
      padding-bottom: 20px;
      position: relative;

      .input-password .strength-meter {
        bottom: 0px;
        position: absolute;
        width: 100%;
      }
    }

    & > .disclaimer {
      color: rgba($base-text-color, 0.7);
      font-family: $font-family-nunito-regular;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
      margin-top: 32px;
      max-width: 410px;

      > a {
        height: 21px;
        font-family: $font-family-nunito-bold;
        color: rgba($base-text-color, 0.7);
        border-bottom: 1px dotted;
        transition: color 300ms;

        &:visited, &:hover, &:active {
          color: rgba($base-text-color, 0.9);
        }
      }
    }

    & > .submit-button {
      margin-top: 40px;
      width: 100%;

      @include responsive (xs-mobile, mobile) {
        margin-top: 32px;
      }
    }

    & > .submit-error {
      font-family: $font-family-nunito-regular;
      color: #FF4B8C;
      font-size: 14px;

      display: flex;
      align-items: center;
      margin-top: 24px;
      transition: max-height 300ms, margin-bottom 300ms, opacity 400ms, transform 200ms;

      transform: scale(1, 1);
      opacity: 1;

      @include responsive (xs-mobile, mobile) {
        margin-top: 32px;
      }

      > span > a {
        color: #FF4B8C;
        border-bottom: 1px dotted;
      }
    }
  }

  & > .form-message {
    margin-top: 8px;

    overflow: hidden;
    max-width: 508px;
    width: 100%;

    @include responsive (xs-mobile, mobile) {
      margin-top: 0px;
      margin-bottom: 16px;
    }
  }

  & > .recaptcha-warning {
    $width: 508px;
    max-width: $width;
    margin-top: 40px;
    text-align: center;
    font-size: 12px;
    font-family: Nunito Sans, sans-serif;
    line-height: 20px;

    @include responsive (xs-mobile, mobile) {
      margin-top: 32px;
      width: 100%;
      min-width: unset;
      max-width: $width;
    }

    & > .link {
      border-bottom: 1px dotted;
      font-weight: 700;
      color: #121e48;
      letter-spacing: -.14px;
      line-height: 22px;
      text-decoration: none;
      opacity: .7;
    }
  }
}

.grecaptcha-badge { visibility: hidden; }

</style>
