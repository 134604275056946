import { InputText, InputPassword } from '@inputs'

export default ({ invalidDomains }) => ({
  fullName: {
    component: InputText,
    style: { 'margin-bottom': '15px' },
    type: 'text',
    validation: 'required|full_name',
    placeholder: 'nome completo',
    hasValidation: true,
    hasLabelTag: true,
    maxLength: 56
  },

  company_name: {
    component: InputText,
    // style: { 'margin-bottom': '15px' },
    validation: 'required',
    type: 'text',
    placeholder: 'nome da empresa',
    hasLabelTag: true,
    maxLength: 56
  },

  email: {
    component: InputText,
    // style: { 'margin-bottom': '40px' },
    type: 'email',
    placeholder: 'e-mail profissional',
    validation: {
      required: true,
      email: true,
      email_invalid_domain: { invalidDomains }
    },
    hasValidation: true,
    hasLabelTag: true,
    maxLength: 56
  },

  phone: {
    component: InputText,
    // style: { 'margin-bottom': '40px' },
    validation: 'required|min:14',
    type: 'text',
    placeholder: 'número de whatsapp',
    mask: [ '(##) ####-####', '(##) #####-####' ],
    hasLabelTag: true,
    maxLength: 56
  },

  password: {
    component: InputPassword,
    placeholder: 'SENHA DE NO MÍNIMO 8 CARACTERES UTILIZANDO LETRAS E NÚMEROS',
    placeholderMobile: 'Senha de no mín. 8 carac. (letras e números)',
    strengths: {
      weak: {
        strength: '40',
        message: 'Senha fraca',
        color: '#FF4B8C'
      },
      moderate: {
        strength: '120',
        message: 'Pode melhorar',
        color: '#FFC24B'
      },
      strong: {
        strength: '_',
        message: '',
        color: '#63E1A5'
      }
    },
    hasStrengthMeter: true,
    validation: 'required|min:8|password',
    ref: 'password',
    maxLength: 56
  },

  password_confirmation: {
    component: InputPassword,
    placeholder: 'repita a senha',
    validation: 'required|min:8|password|password_confirmation:password',
    maxLength: 56
  },
})
